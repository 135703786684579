import {
    ImageComponent as SourceImageComponent,
} from 'SourceComponent/Image/Image.component';

import './Image.override.style';

/** @namespace Zuporder/Component/Image/Component */
export class ImageComponent extends SourceImageComponent {
    // TODO implement logic
}

export default ImageComponent;
